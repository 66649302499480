import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import apiConfig from "../../apiConfig";
import "../Profile/Profile.css";
import { Network } from '@capacitor/network';
import WatiChat from "../../components/WatiChat";
interface OwnProps extends RouteComponentProps { }
const Activehealthstatus: React.FC = () => {
    const darkModeValue = localStorage.getItem("darkMode");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const [ASHstatus, setASHstatus] = useState<any>();

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    const [networkStatus, setNetworkStatus] = useState<any>(true);

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);

    useIonViewWillEnter(() => {
        AHSFunction();
    }, []);
    useEffect(() => {
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
            // if (status.connected == true) {
            //     AHSFunction();
            // }
        });
        // logCurrentNetworkStatus();
    }, []);
    const AHSFunction = () => {
        setLoading(true);
        setBackdropVisible(true);
        axios.get(`${apiConfig.baseUrl}/profile?info=health&id=${localStorage.getItem('userId')}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setASHstatus(response.data.data);
                setLoading(false);
                setBackdropVisible(false);

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        setErrorMessage("Something went wrong on API please try again");
                        setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            });

    }
    const goBack = () => {
        history.goBack();
    };
    return (
        <IonPage>

            {networkStatus == false && darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile dark-profile">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true &&
                darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile dark-profile">
                        <div className="background-holder"></div>

                        <IonImg style={{
                            margin: "-18vh auto -2vh auto",
                            top: "0vh",
                            width: "96%",
                            position: "relative",
                            padding: "0px"
                        }} src="assets/imgs/images/darkAHS.svg"></IonImg>
                        {ASHstatus &&
                            <div className="dark-bottom-section ion-padding-horizontal">
                                <div className="general animate__animated animate__fadeInUp">
                                    <h5 className="dark-bottom-section">General</h5>
                                    <div className="gen-list">
                                        <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                            <div className="thumb" slot="start">
                                                <img src="assets/imgs/icons/dark-all-booking.svg" alt="" />
                                            </div>
                                            <IonLabel className="dark-profile-label">All Bookings</IonLabel>
                                            <IonLabel slot="end" className="dark-profile-label">{ASHstatus.allbookingcount}</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                            <div className="thumb" slot="start">
                                                <img src="assets/imgs/icons/dark-confirm.svg" alt="" />
                                            </div>
                                            <IonLabel className="dark-profile-label">Confirmed</IonLabel>
                                            <IonLabel slot="end" className="dark-profile-label">{ASHstatus.confirmedbookingscount}</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                            <div className="thumb" slot="start">
                                                <img src="assets/imgs/icons/dark-un.svg" alt="" />
                                            </div>
                                            <IonLabel className="dark-profile-label">Unconfirmed</IonLabel>
                                            <IonLabel slot="end" className="dark-profile-label">{ASHstatus.unconfirmedbookingscount}</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                            <div className="thumb" slot="start">
                                                <img src="assets/imgs/icons/dark-cb.svg" alt="" />
                                            </div>
                                            <IonLabel className="dark-profile-label">Completed Bookings</IonLabel>
                                            <IonLabel slot="end" className="dark-profile-label">{ASHstatus.completedcount}</IonLabel>
                                        </IonItem>
                                        <IonItem style={{ marginBottom: "10px" }} lines="none" className="profile-ion-item mrgn-btn-ahs">
                                            <div className="thumb" slot="start">
                                                <img src="assets/imgs/icons/dark-cancel.svg" alt="" />
                                            </div>
                                            <IonLabel className="dark-profile-label">Rejected Bookings</IonLabel>
                                            <IonLabel slot="end" className="dark-profile-label">{ASHstatus.rejectcount}</IonLabel>
                                        </IonItem>
                                    </div>
                                </div>
                            </div>
                        }
                        <IonModal isOpen={showSuspendedModal} id="dark-example-modal-inactive" backdropDismiss={false}>
                            <IonContent className="dark-model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }}
                                                className='fontName booking-details-btn'
                                                onClick={() => setShowSuspendedModal(false)}
                                                expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                    </IonContent>
                </>
            }
            {networkStatus == false && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ textAlign: "center" }}>
                                Edit Profile
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                            <h6 className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ textAlign: "center" }}>
                                Account Health Status
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="animate__animated animate__bounceIn ion-text-center">
                            <IonImg className="ahs-img" src="assets/imgs/images/ahsCard.png"></IonImg>
                        </div>
                        {ASHstatus &&
                            <>
                                <IonLabel className="ahs-title">Overview of Bookings</IonLabel>
                                <div className="gen-list ahs-w-95 profile-card-bg ion-padding-horizontal">
                                    <div className="general animate__animated animate__fadeInUp">
                                        <div className="gen-list">
                                            <div className="border-bottom">
                                                <IonItem lines="none" className="no-bg-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsAllbooking.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">All Bookings</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.all_bookings}</IonLabel>
                                                </IonItem>
                                            </div>
                                            <div className="border-bottom">
                                                <IonItem lines="none" className="no-bg-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsCnfm.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Confirmed</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.confirmed_booking}</IonLabel>
                                                </IonItem>
                                            </div>
                                            <div className="border-bottom">
                                                <IonItem lines="none" className="no-bg-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsUncnfm.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Unconfirmed</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.unconfirmed_booking}</IonLabel>
                                                </IonItem>
                                            </div>
                                            <div className="border-bottom">
                                                <IonItem lines="none" className="no-bg-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsCB.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Completed Bookings</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.completed_booking}</IonLabel>
                                                </IonItem>
                                            </div>
                                            <div className="border-bottom">
                                                <IonItem style={{ marginBottom: "10px" }} lines="none" className="no-bg-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsRB.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Rejected Bookings</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.rejected_booking}</IonLabel>
                                                </IonItem>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                        }
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                        <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
                            <IonContent className="model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                        <IonToast
                            isOpen={showToast}
                            onDidDismiss={() => setShowToast(false)}
                            message={errorMessage}
                            duration={3000}
                            color="danger"
                        />
                    </IonContent>
                </>
            }
            <WatiChat />
        </IonPage>
    );
};

export default Activehealthstatus;
