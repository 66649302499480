import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
    IonPicker, PickerColumn, IonCard, IonToggle
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import apiConfig from "../../apiConfig";
import "./Availability.css";
import { Network } from '@capacitor/network';
import React from "react";
import WatiChat from "../../components/WatiChat";
interface OwnProps extends RouteComponentProps { }
const Availability: React.FC = () => {
    const darkModeValue = localStorage.getItem("darkMode");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    const [networkStatus, setNetworkStatus] = useState<any>(true);

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);

    const [showToasSuccess, setShowToastSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [activePicker, setActivePicker] = useState<{ type: string, dayIndex: number, timeIndex: number }>({ type: '', dayIndex: -1, timeIndex: -1 });
    const [availId, setAvailId] = useState(null);
    const [availability, setAvailability] = useState<Array<any>>([]);
    const [type, setType] = useState('');
    const [selectedHourIndex, setSelectedHourIndex] = useState(0);
    const [selectedMinuteIndex, setSelectedMinuteIndex] = useState(0);

    const hourColumn = {
        name: 'hour',
        options: Array.from({ length: 24 }, (_, i) => ({ text: `${i}`.padStart(2, '0'), value: `${i}` })),
    };

    const minuteColumn = {
        name: 'minute',
        options: Array.from({ length: 60 }, (_, i) => ({ text: `${i}`.padStart(2, '0'), value: `${i}` })),
    };

    const parseTime = (timeString: string) => {
        if (!timeString) {
            return { hour: '00', minute: '00' };
        }
        let [hours, minutes] = timeString.split(':');
        return { hour: hours, minute: minutes };
    };

    const openPicker = (type: string, dayIndex: number, timeIndex: number) => {
        const dayAvailability = availability[dayIndex];
        const timeSlot = dayAvailability.slot_time[timeIndex];

        console.log(`Opening picker for dayIndex ${dayIndex}, timeIndex ${timeIndex}, type ${type}`);

        setAvailId(timeSlot.id);
        setType(type);

        const timeString = type === 'from_time' ? timeSlot.from_time : timeSlot.to_time;

        if (!timeString) {
            setSelectedHourIndex(0);
            setSelectedMinuteIndex(0);
        } else {
            const parsedTime = parseTime(timeString);
            setSelectedHourIndex(parseInt(parsedTime.hour, 10));
            setSelectedMinuteIndex(parseInt(parsedTime.minute, 10));
        }

        setActivePicker({ type, dayIndex, timeIndex });
    };

    const closePicker = () => {
        setActivePicker({ type: '', dayIndex: -1, timeIndex: -1 });
    };

    const handleTimeChange = (value: any) => {
        const newHour = value.hour.value.padStart(2, '0');
        const newMinute = value.minute.value.padStart(2, '0');
        const newTime = `${newHour}:${newMinute}`;

        console.log("New Time:", newTime);

        if (isTimeSlotConflict(newTime, activePicker.type, activePicker.dayIndex, activePicker.timeIndex)) {
            alert("This time slot conflicts with an existing slot. Please select a different time.");
            return false;
        }

        if (!isValidTimeRange(newTime, activePicker.type, activePicker.dayIndex, activePicker.timeIndex)) {
            alert("The 'from' time must be earlier than the 'to' time. Please select a valid time range.");
            return false;
        }

        const updatedAvailability = availability.map((dayAvailability, dayIndex) => {
            if (dayIndex === activePicker.dayIndex) {
                const updatedSlotTime = dayAvailability.slot_time.map((timeSlot: any, timeIndex: number) => {
                    if (timeIndex === activePicker.timeIndex) {
                        console.log(`Updating time slot for dayIndex ${dayIndex}, timeIndex ${timeIndex} to ${newTime}`);
                        return { ...timeSlot, [activePicker.type]: newTime };
                    }
                    return timeSlot;
                });
                return { ...dayAvailability, slot_time: updatedSlotTime };
            }
            return dayAvailability;
        });

        console.log("Updated Availability:", updatedAvailability);

        // Update state
        setAvailability(updatedAvailability);

        // Transform and send payload
        transformAvailabilityToPayload(updatedAvailability);

        closePicker();
        return true;
    };


    const isValidTimeRange = (newTime: string, type: string, dayIndex: number, timeIndex: number) => {
        const dayAvailability = availability[dayIndex];
        const newTimeInMinutes = convertTimeToMinutes(newTime);

        // If this is a new slot, it's always valid
        if (timeIndex >= dayAvailability.slot_time.length) {
            return true;
        }

        const timeSlot = dayAvailability.slot_time[timeIndex];
        const fromTimeInMinutes = convertTimeToMinutes(timeSlot.from_time);
        const toTimeInMinutes = convertTimeToMinutes(timeSlot.to_time);

        if (type === 'from_time' && newTimeInMinutes >= toTimeInMinutes) {
            return false;
        }

        if (type === 'to_time' && newTimeInMinutes <= fromTimeInMinutes) {
            return false;
        }

        return true;
    };

    const convertTimeToMinutes = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const renderPicker = () => {
        if (activePicker.dayIndex === -1 || activePicker.timeIndex === -1) return null;

        const dayAvailability = availability[activePicker.dayIndex];
        const timeSlot = dayAvailability.slot_time[activePicker.timeIndex];
        const selectedTime = timeSlot[activePicker.type];
        const { hour, minute } = parseTime(selectedTime);

        const updatedHourColumn = {
            ...hourColumn,
            selectedIndex: selectedHourIndex
        };

        const updatedMinuteColumn = {
            ...minuteColumn,
            selectedIndex: selectedMinuteIndex
        };

        console.log("Render Picker - Selected Time:", selectedTime);

        return (
            <IonPicker
                isOpen={activePicker.dayIndex !== -1 && activePicker.timeIndex !== -1}
                columns={[updatedHourColumn, updatedMinuteColumn]}
                onDidDismiss={closePicker}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: closePicker,
                        cssClass: 'cancel-button'
                    },
                    {
                        text: 'Confirm',
                        handler: (selected) => {
                            if (handleTimeChange(selected)) {
                                closePicker();
                            }
                        },
                        cssClass: 'confirm-button'
                    },
                ]}
            />
        );
    };

    const transformAvailabilityToPayload = (updatedAvailability: any) => {
        const daysMap: { [key: string]: string } = {
            "Monday": "mon",
            "Tuesday": "tue",
            "Wednesday": "wed",
            "Thursday": "thu",
            "Friday": "fri",
            "Saturday": "sat",
            "Sunday": "sun"
        };

        const payload: { [key: string]: any[] } = {};

        updatedAvailability.forEach((avail: { slot_day: string | number; slot_time: any[]; }) => {
            const dayKey = daysMap[avail.slot_day as keyof typeof daysMap];
            if (!dayKey) {
                console.error(`Invalid slot_day: ${avail.slot_day}`);
                return;
            }
            if (!payload[dayKey]) {
                payload[dayKey] = [];
            }

            avail.slot_time.forEach((timeSlot: any) => {
                payload[dayKey].push({
                    id: timeSlot.id,
                    from: timeSlot.from_time,
                    to: timeSlot.to_time
                });
            });
        });

        console.log("Transformed Payload:", JSON.stringify(payload, null, 2));
        handleFromToDate(payload);
        return payload;
    };

    const handleFromToDate = (payload: any) => {
        console.log("Payload to send:", JSON.stringify(payload));

        setLoading(true);
        setBackdropVisible(true);

        axios.patch(`${apiConfig.baseUrl}/newslot/${localStorage.getItem('userId')}`, payload, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setLoading(false);
                setBackdropVisible(false);
                setShowToastSuccess(true);
                setSuccessMessage(res.data.message);
                availGetwithoutLoaderFunction();
                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            })
            .catch((error) => {
                console.log('Errorrrrr-->>', error);
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            })
    };

    const handleToggleChange = (event: any, avail: any) => {
        console.log("event.detail.checked", event.detail.checked);

        const isChecked = event.detail.checked == true ? 1 : 2;;
        console.log("isChecked", isChecked);

        let day = "";
        if (avail.slot_day === "Monday") {
            day = "mon";
        } else if (avail.slot_day === "Tuesday") {
            day = "tue";
        } else if (avail.slot_day === "Wednesday") {
            day = "wed";
        } else if (avail.slot_day === "Thursday") {
            day = "thu";
        } else if (avail.slot_day === "Friday") {
            day = "fri";
        } else if (avail.slot_day === "Saturday") {
            day = "sat";
        } else if (avail.slot_day === "Sunday") {
            day = "sun";
        } else {
            console.error("Invalid slot_day value:", avail.slot_day);
            return;
        }

        setLoading(true);
        setBackdropVisible(true);

        axios.patch(`${apiConfig.baseUrl}/newslot/${localStorage.getItem('userId')}`, {
            "user_id": localStorage.getItem('userId'),
            "status": isChecked,
            "day": day
        }, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setShowToastSuccess(true);
                setSuccessMessage(res.data.message);
                availGetFunction();
                setLoading(false);
                setBackdropVisible(false);

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            })
            .catch((error) => {
                console.log('Errorrrrr-->>', error);
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            })
    }

    const handleDeleteIcon = (event: any, avail: any) => {

        const isChecked = event.detail.checked == true ? 1 : 2;;
        console.log("isChecked", isChecked);

        setLoading(true);
        setBackdropVisible(true);

        axios.patch(`${apiConfig.baseUrl}/newslot/${localStorage.getItem('userId')}`, {
            "slot_id": avail.id,
            "slot_status": 2
        }, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setShowToastSuccess(true);
                setSuccessMessage(res.data.message);
                availGetFunction();
                setLoading(false);
                setBackdropVisible(false);

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        // setErrorMessage("Something went wrong on API please try again");
                        // setShowToast(true);
                    }
                }, 10000);
            })
            .catch((error) => {
                console.log('Errorrrrr-->>', error);
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            })
    }



    const isTimeSlotConflict = (newTime: string, type: string, dayIndex: number, timeIndex: number) => {
        const dayAvailability = availability[dayIndex];
        const newTimeInMinutes = convertTimeToMinutes(newTime);

        for (let i = 0; i < dayAvailability.slot_time.length; i++) {
            if (i === timeIndex) continue;

            const slot = dayAvailability.slot_time[i];
            const fromTimeInMinutes = convertTimeToMinutes(slot.from_time);
            const toTimeInMinutes = convertTimeToMinutes(slot.to_time);

            if (type === 'from_time' && (newTimeInMinutes >= fromTimeInMinutes && newTimeInMinutes < toTimeInMinutes)) {
                return true;
            }

            if (type === 'to_time' && (newTimeInMinutes > fromTimeInMinutes && newTimeInMinutes <= toTimeInMinutes)) {
                return true;
            }
        }

        return false;
    };


    const addSlot = (day: string) => {
        let lastToTime = "00:00";
        let dayIndex = -1;

        // Find the last "to" time for the specified day and the index of the day
        const dayAvailability = availability.find((avail, index) => {
            if (avail.slot_day === day) {
                dayIndex = index;
                return true;
            }
            return false;
        });
        if (dayAvailability) {
            const lastSlot = dayAvailability.slot_time[dayAvailability.slot_time.length - 1];
            if (lastSlot && lastSlot.to_time) {
                lastToTime = lastSlot.to_time;

                // Check if the last slot reaches the maximum end time
                if (lastToTime === "23:59") {
                    alert("No more slots can be added as the last slot ends at 23:59.");
                    return;
                }
            }
        }

        // Calculate the new "from" time as one minute after the last "to" time
        const [lastHour, lastMinute] = lastToTime.split(':').map(Number);
        let newHour = lastHour;
        let newMinute = lastMinute + 1;
        if (newMinute === 60) {
            newMinute = 0;
            newHour += 1;
        }
        if (newHour === 24) {
            newHour = 0;
        }
        const newFromTime = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;

        // Check for conflicts and valid time ranges
        if (isTimeSlotConflict(newFromTime, 'from_time', dayIndex, dayAvailability.slot_time.length)) {
            alert("This time slot conflicts with an existing slot. Please select a different time.");
            return false;
        }

        if (!isValidTimeRange(newFromTime, 'from_time', dayIndex, dayAvailability.slot_time.length)) {
            alert("The 'from' time must be earlier than the 'to' time. Please select a valid time range.");
            return false;
        }

        // Add new slot
        const newSlot = { "from_time": newFromTime, "to_time": "23:59" };
        const updatedAvailability = availability.map((avail, index) => {
            if (index === dayIndex) {
                return { ...avail, slot_time: [...avail.slot_time, newSlot] };
            }
            return avail;
        });

        // Update state and send updated availability to the server
        setAvailability(updatedAvailability);
        console.log("Updated Availability:", updatedAvailability);

        // Send updated availability to the server
        transformAvailabilityToPayload(updatedAvailability);
    };



    useIonViewWillEnter(() => {
        availGetFunction();
    }, []);

    useEffect(() => {
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);

        });
    }, []);

    const availGetFunction = () => {
        setLoading(true);
        setBackdropVisible(true);
        axios.get(`${apiConfig.baseUrl}/newslot?id=${localStorage.getItem('userId')}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setAvailability(response.data.data);
                setLoading(false);
                setBackdropVisible(false);

                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        setErrorMessage("Something went wrong on API please try again");
                        setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            });

    }

    const availGetwithoutLoaderFunction = () => {
        // setLoading(true);
        // setBackdropVisible(true);
        axios.get(`${apiConfig.baseUrl}/newslot?id=${localStorage.getItem('userId')}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setAvailability(response.data.data);
                // setLoading(false);
                // setBackdropVisible(false);

                setTimeout(() => {
                    if (loading == true) {
                        // setLoading(false);
                        // setBackdropVisible(false);
                        setErrorMessage("Something went wrong on API please try again");
                        setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                // setLoading(false);
                // setBackdropVisible(false);
            });

    }

    const goBack = () => {
        history.goBack();
    };
    return (
        <IonPage>

            {networkStatus == false && darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile dark-profile">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true &&
                darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile dark-profile">
                        <div className="background-holder"></div>

                        <IonImg style={{
                            margin: "-18vh auto -2vh auto",
                            top: "0vh",
                            width: "96%",
                            position: "relative",
                            padding: "0px"
                        }} src="assets/imgs/images/darkAHS.svg"></IonImg>
                        {availability &&
                            <div className="dark-bottom-section ion-padding-horizontal">
                                <div className="general animate__animated animate__fadeInUp">
                                    <h5 className="dark-bottom-section">General</h5>

                                </div>
                            </div>
                        }
                        <IonModal isOpen={showSuspendedModal} id="dark-example-modal-inactive" backdropDismiss={false}>
                            <IonContent className="dark-model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }}
                                                className='fontName booking-details-btn'
                                                onClick={() => setShowSuspendedModal(false)}
                                                expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                    </IonContent>
                </>
            }
            {networkStatus == false && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ textAlign: "center" }}>
                                Edit Profile
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="no-network-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                            <h6 className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="light-mode-header-toolbar">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrow.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ textAlign: "center" }}>
                                Availability
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bell.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                            <IonGrid className="t-d-none d-d-none">
                                <IonRow>
                                    <IonCol size="12">
                                        <IonButton expand="block" routerLink="/unavailable-dates"
                                            routerDirection="none" className="save-btn-profile">
                                            Unavailable Dates
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                        <div className="general animate__animated animate__fadeInUp">
                            <div className="slots-title slots-div">
                                <IonLabel style={{ fontSize: '20px' }} className="main-title">Slots</IonLabel>
                                <IonButton expand="block" routerLink="/unavailable-dates"
                                    routerDirection="none" className="save-btn-profile unavail-btn m-d-none">
                                    Unavailable Dates
                                </IonButton>
                            </div>
                            <IonGrid className="margin-top-unavail center-div">
                                {availability && availability.map((avail: any, dayIndex: any) => (
                                    <IonCol key={dayIndex} size-md="4" size-lg="3" size="12">
                                        <IonCard className="avail-list">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="6">
                                                        <IonLabel className="day-title">{avail.slot_day}</IonLabel>
                                                    </IonCol>
                                                    <IonCol size="6">
                                                        <div className="div-align ion-float-right">
                                                            <IonToggle
                                                                className="toggleActive"
                                                                slot="end"
                                                                checked={avail.status == "1" ? true : false}
                                                                onIonChange={(e) => handleToggleChange(e, avail)}
                                                            ></IonToggle>
                                                        </div>
                                                    </IonCol>
                                                    {avail.slot_time.map((timeSlot: any, timeIndex: any) => (
                                                        <React.Fragment key={`${dayIndex}-${timeIndex}`}>
                                                            <IonCol size="5">
                                                                <div className="model-container">
                                                                    <IonLabel className="label-control fontName" onClick={() => openPicker('from_time', dayIndex, timeIndex)}>{timeSlot.from_time}</IonLabel>
                                                                    <label className="label-outline-model">Start</label>
                                                                </div>
                                                            </IonCol>
                                                            <IonCol size="5">
                                                                <div className="model-container">
                                                                    <IonLabel className="label-control fontName" onClick={() => openPicker('to_time', dayIndex, timeIndex)}>{timeSlot.to_time}</IonLabel>
                                                                    <label className="label-outline-model">End</label>
                                                                </div>
                                                            </IonCol>
                                                            <IonCol size="2" className="col-d-flex">
                                                                {timeIndex === 0 ? (
                                                                    <><IonImg onClick={() => addSlot(avail.slot_day)} className="avail-icon d-d-none" src="assets/imgs/icons/availPlusIcon.svg"></IonImg>
                                                                        <IonButton expand="block" onClick={() => addSlot(avail.slot_day)} className="accept-btn m-d-none plus-reject-btn">
                                                                            <IonIcon
                                                                                className="fav btn-icon m-d-none"
                                                                                src="assets/imgs/icons/btnPlus.svg"
                                                                                slot="end" /><span className="order-span">Add Slot</span>
                                                                        </IonButton>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IonImg className="avail-icon d-d-none" onClick={(e) => handleDeleteIcon(e, timeSlot)} src="assets/imgs/icons/availDeleteIcon.svg"></IonImg>
                                                                        <IonButton expand="block" onClick={(e) => handleDeleteIcon(e, timeSlot)} className="reject-btn m-d-none plus-reject-btn">
                                                                            <IonIcon
                                                                                className="fav btn-icon m-d-none"
                                                                                src="assets/imgs/icons/btnDelete.svg"
                                                                                slot="end" />
                                                                            <span className="order-span">Delete Slot</span>
                                                                        </IonButton>
                                                                    </>
                                                                )}
                                                            </IonCol>
                                                        </React.Fragment>
                                                    ))}
                                                </IonRow>
                                            </IonGrid>
                                        </IonCard>
                                    </IonCol>
                                ))}
                            </IonGrid>
                        </div>
                        {renderPicker()}
                        <IonToast
                            isOpen={showToasSuccess}
                            onDidDismiss={() => setShowToastSuccess(false)}
                            message={successMessage}
                            duration={3000}
                            color="success" />
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                        <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
                            <IonContent className="model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                        <IonToast
                            isOpen={showToast}
                            onDidDismiss={() => setShowToast(false)}
                            message={errorMessage}
                            duration={3000}
                            color="danger"
                        />
                    </IonContent>
                </>
            }
            <WatiChat />
        </IonPage>
    );
};

export default Availability;
