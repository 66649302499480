import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import apiConfig from "../../apiConfig";
import "./Requestreview.css";

import { Network } from '@capacitor/network';
import WatiChat from "../../components/WatiChat";

interface OwnProps extends RouteComponentProps { }

const Requestreview: React.FC<OwnProps> = ({ history }) => {

  const [serviceAssigned, setServiceAssigned] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);
  useIonViewWillEnter(() => {
    getServiceCategoryAssigned();
    console.log("darkModeValue", localStorage.getItem("darkMode"));
    if (localStorage.getItem("darkMode") == "darkMode") {
      setIsDarkModeActive(true);
    } else {
      setIsDarkModeActive(false);
    }
  }, [])

  const getServiceCategoryAssigned = () => {
    setLoading(true);
    setBackdropVisible(true);
    axios.post(`${apiConfig.baseUrl}/servicecategory`, {
      "token": localStorage.getItem("fcmToken")
    })
      .then(response => {
          console.log("response", response.data.services);
          setServiceAssigned(response.data.services);
          setLoading(false);
          setBackdropVisible(false);
        
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
          setShowSuspendedModal(true);
        }
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
  }


  const goBack = () => {
    history.goBack();
  };
  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == false &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">
              Assigned Categories
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader><IonContent fullscreen className="request-review">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == false &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">
              Assigned Categories
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader><IonContent fullscreen className="request-review">
            <div className="v-profile-last-card animate__animated animate__fadeInUp animate__slow ion-text-center">
              <IonGrid>
                <IonRow>
                  {serviceAssigned.map((service) => (
                    <IonCol col-6 size="6">
                      <h6 className="service-cat-chip-view">{service.title}</h6>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </div>
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false); }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {networkStatus == false && isDarkModeActive == true &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">
              Assigned Categories
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == true &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">
              Assigned Categories
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="dark-v-profile-last-card animate__animated animate__fadeInUp animate__slow ion-text-center">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonLabel className="dark-login-edit-title">Assigned Categories</IonLabel>
                  </IonCol>
                  {serviceAssigned.map((service) => (
                    <IonCol col-6 size="6">
                      <h6 className="service-cat-chip-view">{service.title}</h6>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </div>
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false); }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      <WatiChat />
    </IonPage>
  );
};

export default Requestreview;
