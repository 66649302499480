// hooks/useScript.ts
import { useEffect, useState } from 'react';

const useScript = (url: string, callback?: () => void): boolean => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    const onScriptLoad = () => {
      setLoaded(true);
      if (callback) {
        callback();
      }
    };

    const onScriptError = () => {
      setLoaded(false);
    };

    script.onload = onScriptLoad;
    script.onerror = onScriptError;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, callback]);

  return loaded;
};

export default useScript;
