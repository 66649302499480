// components/WatiChat.tsx
import React, { useEffect, useState } from 'react';
import useScript from '../hooks/useScript';

declare global {
    interface Window {
        CreateWhatsappChatWidget: (options: any) => void;
    }
}

const WatiChat: React.FC = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [welcomeText, setWelcomeText] = useState('Hey, how can we help you today?');

    useEffect(() => {
        const userName = localStorage.getItem("userName");
        const lastName = localStorage.getItem("lastName");

        if (userName && lastName) {
            setWelcomeText(`Hey ${userName} ${lastName}, how can we help you today?`);
        }
    }, []);

    const watiSettings = {
        enabled: true,
        chatButtonSetting: {
            backgroundColor: "#00e785",
            borderRadius: "25",
            marginLeft: "0",
            marginRight: "20",
            ctaIconWATI: false,
            position: "right",
        },
        brandSetting: {
            brandName: "Your Brand Name",
            brandSubTitle: "undefined",
            brandImg: "[WATICHATLOGO]",
            welcomeText: welcomeText,
            messageText: "Hi, I am a Glamdeva pro, I need some assistance",
            backgroundColor: "#00e785",
            ctaText: "Start Chat Now",
            borderRadius: "25",
            autoShow: false,
            phoneNumber: "447585633773",
        },
    };

    const scriptLoaded = useScript('https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?71736');

    useEffect(() => {
        if (scriptLoaded && window.CreateWhatsappChatWidget) {
            window.CreateWhatsappChatWidget(watiSettings);
        } else if (!scriptLoaded) {
            console.warn('Wati script failed to load');
        }
    }, [scriptLoaded, welcomeText]);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const redirectToWhatsApp = () => {
        const phoneNumber = '447585633773';
        const messageText = 'Hi, I am a Glamdeva pro, I need some assistance';
        const encodedText = encodeURIComponent(messageText);

        const link = `https://wa.me/${phoneNumber}?text=${encodedText}`;
        window.open(link, '_blank');
    };

    return (
        <>
            <div id="wati-widget">
                {!isChatOpen && (
                    <div
                        className="wati-chat-button"
                        style={{ display: window.innerWidth >= 768 ? 'flex' : 'none' }}
                        onClick={toggleChat}
                    >
                        <img
                            alt="whatsapp logo"
                            title="whatsapp logo"
                            className="w-logo"
                            src="https://afh.ams3.cdn.digitaloceanspaces.com/whatsapp-QR-code/whatsapp_widget.svg"
                            width="200"
                            height="63"
                        />
                    </div>
                )}
                {isChatOpen && (
                    <button className="w-close-icon" onClick={toggleChat}>×</button>
                )}
                <div className={`wati-chat-modal m-d-none ${isChatOpen ? 'active' : ''}`}>
                    <div className="msgboxxchat-window">
                        <div className="msgboxxchat-window__header">
                            <p className="msgboxx-title">
                                <svg className="bi bi-chat-fill">
                                    <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z"></path>
                                </svg>
                                Message us on WhatsApp
                            </p>
                        </div>
                        <div className="msgboxxchat-window__messages">
                            <div className="msgboxxchat-window__messages__options">
                                <p className="msgboxx-p">
                                    <span className="w-arrow">
                                        <svg className="bi bi-chevron-right">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                        </svg>
                                    </span>
                                    <span style={{ margin: '-2px 2px' }}>Scan this QR with your phone</span>
                                </p>
                                <div className="qr-code_section">
                                    <img
                                        id="qrCodeImage"
                                        src="https://afh.ams3.cdn.digitaloceanspaces.com/whatsapp-QR-code/glamdeva-qr-code.png"
                                        alt="QR Code"
                                        title="QR Code"
                                        height="500"
                                        width="500"
                                    />
                                </div>
                                <div className="or_line">
                                    <span> OR </span>
                                </div>
                                <div>
                                    <button className="msgboxx-button" onClick={redirectToWhatsApp}>
                                        WhatsApp Web
                                        <svg className="bi bi-box-arrow-up-right">
                                            <path d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a.5.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                            <path d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WatiChat;