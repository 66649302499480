import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import fingerprintService from "../../fingerprint.service";
import "./Getstarted.css";
import { PushNotifications } from "@capacitor/push-notifications";
import { Network } from "@capacitor/network";

const Getstarted: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");
  const newUser = localStorage.getItem("newUser");

  const history = useHistory();

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState("");
  const [supendedTitle, setSupendedTitle] = useState("");
  const [notificationTap, setNotificationTap] = useState("");
  const [bookingId, setBookingId] = useState("");

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);
  const [networkStatus, setNetworkStatus] = useState<any>(true);

  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status.connected);
      setNetworkStatus(status.connected);
    });

    // localStorage.setItem("fcmToken", "APA91bEVc2hGSDGG245gwrgrwgAI2SlP4fcvwegvfcVRERGwefvWsrgrgEGHwrgWRG235GWluCOviMsACgNZdcHEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");


    PushNotifications.register();
    addListeners();

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, []);

  const addListeners = async () => {
    await PushNotifications.addListener("registration", (token) => {
      console.info("Registration token: ", token.value);
      if (token && token.value) {
        localStorage.setItem("fcmToken", token.value);
      } else {
        console.warn("FCM token is null");
        retryTokenRegistration();
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
      retryTokenRegistration();
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification.body);
        const notifyBody = notification.body;
        const idMatch = notifyBody?.match(/Booking ID: (UK-\d+)/); // Adjusted regex pattern
        if (idMatch) {
          const id = idMatch[1]; // Get the first capturing group
          console.log("Extracted ID: ", id);

          // Debugging statements to check the flow
          localStorage.setItem("bookingId", id);
          console.log(
            "localStorage updated with ID: ",
            localStorage.getItem("bookingId")
          );

          setBookingId(id);
          console.log("Booking ID set: ", id);
        } else {
          console.log("ID not found in the notification body.");
          localStorage.setItem("bookingId", "");
        }
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log("Push notification action performed", notification);
        if (notification.actionId === "tap") {
          setNotificationTap(notification.actionId);
          if (!bookingId) {
            history.push("/notifications");
          } else {
            console.log("avail id notifications --------->>>");
            history.push("/viewbooking");
          }
        }
      }
    );
  };

  const retryTokenRegistration = () => {
    setTimeout(() => {
      PushNotifications.register();
    }, 5000);
  };

  useIonViewWillEnter(() => {
    if (newUser == "") {
      localStorage.setItem("newUser", "no");
    } else {
      localStorage.setItem("newUser", "yes");
    }
    if (darkModeValue == "darkMode") {
      localStorage.setItem("darkMode", "darkMode");
    } else if (darkModeValue == "") {
      localStorage.setItem("darkMode", "lightMode");
    } else {
      localStorage.setItem("darkMode", "lightMode");
    }

    console.log("accessToken", localStorage.getItem("accessToken"));

    if (newUser == "yes") {
      if (localStorage.getItem("accessToken") == null) {
        history.push("/login");
      } else {
        unlockAppWithFingerprint();
      }
    } else {
      setTimeout(() => {
        history.push("/intro");
      }, 3000);
    }
  }, []);

  async function unlockAppWithFingerprint() {
    try {
      await fingerprintService
        .authenticate()
        .then((result_1: any) => {
          console.log("Inside success", result_1);
          if (notificationTap === "tap") {
            if (!bookingId) {
              history.push("/notifications");
            } else {
              console.log("avail id notifications --------->>>");
              history.push("/viewbooking");
            }
          } else {
            if (localStorage.getItem("loggedOut") == "loggedOut") {
              history.push("/login");
            } else {
              history.push("/tabs/dashboard");
            }
          }
        })
        .catch((error: any) => {
          console.error("error--->>Fingerprint", error);
          if (
            error.message == "Authentication canceled." ||
            error.message == "BIOMETRIC_DISMISSED" ||
            error.code == -108 ||
            error.message == "BIOMETRIC_PIN_OR_PATTERN_DISMISSED" ||
            error.code == -109 ||
            error == "cordova_not_available"
          ) {
            history.push("/login");
          } else {
            history.push("/tabs/dashboard");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <IonPage>
      {networkStatus == false && (
        <div className="no-network-div">
          <IonImg
            className="no-network-img"
            src="assets/imgs/images/noNetwork.svg"
          ></IonImg>
          <h6 className="fontName">
            Check your internet connection and try again
          </h6>
        </div>
      )}
      {networkStatus == true && (
        <IonContent className="ion-padding overflow" fullscreen>
          <div className="background-img-container">
            <div className="gradient-overlay"></div>
            <img
              src="assets/imgs/images/setStartedBg.png"
              alt="Background Image"
            />
          </div>
          <div className="landscape-background-img-container">
            <div className="gradient-overlay"></div>
            <img
              src="assets/imgs/images/gd-landscape-image.jpg"
              alt="Background Image"
            />
          </div>

          <IonGrid className="getStarted-grid">
            <IonRow>
              <IonCol size="12" className="getStarted-col">
                <IonImg
                  className="afh-logo"
                  src="assets/imgs/images/glamdevaLogo.svg"
                  alt="Logo Image"
                ></IonImg>
              </IonCol>
              <IonCol size="12">
                <div className="m-title-getstarted getstarted-title">
                  <IonLabel className="getstarted-msg">
                    Welcome to
                    <br /> Glamdeva PRO Centre.
                  </IonLabel>
                  <br />
                  <IonLabel className="letsgrow-msg">
                    Let’s grow up your{" "}
                    <span className="bg-color">Business together!</span>
                  </IonLabel>
                </div>
              </IonCol>
              <IonCol size="12">
                <IonButton
                  expand="block"
                  className="getstarted-btn"
                  routerLink="/intro"
                >
                  Get Started
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonModal
            isOpen={showSuspendedModal}
            id="example-modal-inactive"
            backdropDismiss={false}
          >
            <IonContent className="model-bg">
              <IonGrid className="cnfmModelGrid">
                <IonRow>
                  <IonCol size="12">
                    <IonImg
                      src="assets/imgs/icons/warning.svg"
                      alt="Product"
                      className="tick-img"
                    ></IonImg>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel className="fontName b-cnfmed-title">
                      {supendedTitle}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel className="fontName thanks-cnfmed-title">
                      {supendedMsg}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12">
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }}
                      className="fontName booking-details-btn"
                      onClick={() => setShowSuspendedModal(false)}
                      expand="block"
                    >
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Getstarted;
