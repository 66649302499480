import React, { useEffect, useRef, useState } from 'react';

// Utility function to load Google Maps script
const loadGoogleMapsScript = (callback: () => void) => {
  const scriptId = 'googleMapsScript';
  if (!document.getElementById(scriptId)) {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBxx9vKxSe7qftcutFSbgvxC2LGZJx_s8E&libraries=places';
    script.id = scriptId;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};

// Define a type for your props
interface AddressAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  resetInput: boolean; // Prop to control the reset from the parent component
  onResetDone: () => void; // Callback to inform the parent component that the reset is complete
  onInputChange: () => void;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onPlaceSelect, resetInput, onResetDone, onInputChange }) => {
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [autocompleteInstance, setAutocompleteInstance] = useState<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      setIsScriptLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (isScriptLoaded && window.google && autocompleteRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address) {
          setInputValue(place.formatted_address); // Update the input value with the selected address
          onPlaceSelect(place);
        }
      });
      setAutocompleteInstance(autocomplete);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (autocompleteInstance) {
        window.google.maps.event.clearInstanceListeners(autocompleteInstance);
      }
    };
  }, [isScriptLoaded, onPlaceSelect]);

  useEffect(() => {
    if (resetInput) {
      setInputValue(""); // Clear the input value
      onResetDone(); // Inform the parent component that the reset is complete
    }
  }, [resetInput, onResetDone]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onInputChange(); // Notify the parent component about the input change
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && autocompleteRef.current) {
      e.preventDefault();
      const place = autocompleteInstance?.getPlace();
      if (place && place.formatted_address) {
        setInputValue(place.formatted_address); // Update the input value with the selected address
        onPlaceSelect(place);
      }
    }
  };

  return (
    <div className="model-container">
      <input
        ref={autocompleteRef}
        type="text"
        placeholder="Enter your address"
        className="form-control-model-fp-signup fontName"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Address<span style={{ color: '#FF0000' }}>*</span></label>
    </div>
  );
};

export default AddressAutocomplete;
