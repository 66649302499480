import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonPicker,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";


import axios from "axios";
import apiConfig from "../../apiConfig";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./Unavailabledates.css";
import { View } from "react-calendar/dist/cjs/shared/types";
import WatiChat from "../../components/WatiChat";

const Unavailabledates: React.FC = () => {
    const history = useHistory();



    // const [loading, setLoading] = useState(false);
    // const [backdropVisible, setBackdropVisible] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');
    // const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    // const [showToast, setShowErrorToast] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');

    const [showToasSuccess, setShowToastSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    // const [networkStatus, setNetworkStatus] = useState<any>(true);

    const [selectedDatesList, setSelectedDatesList] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [clickedDates, setClickedDates] = useState<string[]>([]);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [suspendedMsg, setSuspendedMsg] = useState("");
    const [suspendedTitle, setSuspendedTitle] = useState("");
    const [networkStatus, setNetworkStatus] = useState(true);
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const goBack = () => {
        history.goBack();
    };

    useIonViewWillEnter(() => {
        setCurrentMonth(new Date());
        getUnAvailSlot();
    }, []);

    const getUnAvailSlot = async () => {
        console.log("currentMonth", currentMonth);

        setLoading(true);
        setBackdropVisible(true);

        axios.get(`${apiConfig.baseUrl}/newnoslot?id=${localStorage.getItem('userId')}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status === false) {
                    setShowErrorToast(true);
                    setErrorMessage(response.data.message);
                } else {
                    const formattedDates = response.data.data.map((slot: any) => slot.slotdate);
                    setSelectedDatesList(response.data.data);
                    const slots = response.data.data.map((slot: any) => new Date(slot.slotdate));
                    console.log('Parsed slots:', slots); // Log the parsed dates
                    setSelectedDates(slots);
                    setClickedDates(formattedDates);
                }
            })
            .catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    setSuspendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSuspendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                setNetworkStatus(error.code !== "ERR_NETWORK");
            })
            .finally(() => {
                setLoading(false);
                setBackdropVisible(false);
            });
    };

    const handleDateClick = (date: Date) => {
        const formattedDate = formatDate(date);
        setClickedDates(prevDates => {
            const newDates = prevDates.includes(formattedDate)
                ? prevDates.filter(d => d !== formattedDate)
                : [...prevDates, formattedDate];
            console.log('Updated clickedDates:', newDates);
            return newDates;
        });
    };

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const isTileDisabled = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const now = new Date();
            return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
        }
        return false;
    };

    const getClassNamesForDate = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const formattedDate = formatDate(date);
            const isSelected = clickedDates.includes(formattedDate);
            console.log(`Date: ${formattedDate}, isSelected: ${isSelected}`);
            if (isSelected) return 'highlight';
            return '';
        }
        return '';
    };

    const handleActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date | null }) => {
        if (activeStartDate) {
            setCurrentMonth(activeStartDate);
        }
    };


    useEffect(() => {
        console.log(selectedDates);
    }, [selectedDates]);

    const deleteUnavailDate = (id: any) => {
        setLoading(true);
        setBackdropVisible(true);

        axios.delete(`${apiConfig.baseUrl}/noslot/${id}`, {
            headers: {
                'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status == false) {
                    setShowErrorToast(true);
                    setErrorMessage(response.data.message);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    setShowToastSuccess(true);
                    setSuccessMessage(response.data.message);
                    getUnAvailSlot();
                }

            })
            .catch(error => {
                console.error(error);
                if (error.response.status == 401) {
                    setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                    setSupendedTitle("Inactive");
                    setShowSuspendedModal(true);
                }
                setLoading(false);
                setBackdropVisible(false);
                setNetworkStatus(error.code !== "ERR_NETWORK");
            })
            .finally(() => {
                setLoading(false);
                setBackdropVisible(false);
            });
    }


    const updateUnAvailSlot = () => {
        console.log(clickedDates);
        if (clickedDates.length !== 0) {
            setLoading(true);
            setBackdropVisible(true);

            axios.post(`${apiConfig.baseUrl}/noslot`, {
                "userid": localStorage.getItem('userId'),
                "slotdate": clickedDates
            }, {
                headers: {
                    'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.status == false) {
                        setShowErrorToast(true);
                        setErrorMessage(response.data.message);
                        setLoading(false);
                        setBackdropVisible(false);
                    } else {
                        setShowToastSuccess(true);
                        setSuccessMessage(response.data.message);
                        getUnAvailSlot();
                        setLoading(false);
                        setBackdropVisible(false);
                    }

                    setTimeout(() => {
                        if (loading == true) {
                            setLoading(false);
                            setBackdropVisible(false);
                            setErrorMessage("Something went wrong on API please try again");
                            setShowErrorToast(true);
                        }
                    }, 10000);
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status == 401) {
                        setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
                        setSupendedTitle("Inactive");
                        setShowSuspendedModal(true);
                    }
                    if (error.code == "ERR_NETWORK") {
                        setNetworkStatus(false);
                    } else {
                        setNetworkStatus(true);
                    }
                    setLoading(false);
                    setBackdropVisible(false);
                });
        } else {
            setErrorMessage("Please select atleast one date");
            setShowErrorToast(true);
        }
    }


    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar className="light-mode-header-toolbar">
                    <IonButtons slot="start">
                        <IonButton
                            className="end-btn"
                            onClick={goBack}
                        >
                            <IonIcon
                                className="fav header-icons"
                                src="assets/imgs/icons/backArrow.svg"
                                slot="end" />
                        </IonButton>
                    </IonButtons>
                    <IonTitle style={{ textAlign: "center" }}>
                        Unavailable Dates
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            className="end-btn"
                            routerLink="/notifications"
                            routerDirection="none"
                        >
                            <IonIcon
                                className="fav header-icons"
                                src="assets/imgs/icons/bell.svg"
                                slot="end" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding-horizontal" fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol size-md="6" size="12" push-md="6">
                            <IonLabel className="select-title">Select a date</IonLabel>
                            <div className="animate__animated animate__bounceIn ion-text-center">
                                <Calendar
                                    tileClassName={getClassNamesForDate}
                                    onClickDay={handleDateClick}
                                    tileDisabled={isTileDisabled}
                                    value={currentMonth}
                                    onActiveStartDateChange={handleActiveStartDateChange}
                                />

                                <IonButton expand="block" onClick={updateUnAvailSlot} className="update-btn send-fp">
                                    <IonIcon
                                        className="fav btn-icon"
                                        src="assets/imgs/icons/editPlus.svg"
                                        slot="end"
                                    /><span className="order-span">Update Unavailable Slots</span>
                                </IonButton>
                            </div>
                        </IonCol>
                        <hr className="d-d-none" />
                        <IonCol size-md="6" size="12" pull-md="6">
                            <IonLabel className="unavail-title">Unavailable for these dates</IonLabel>
                            {selectedDatesList.length !== 0 &&
                                <IonCard className="list-card" >
                                    {selectedDatesList.map((date: any, i: any) =>
                                        <>
                                            <IonItem key={i} lines="none">
                                                <IonLabel slot="start" className="list-label">{date.slotdate}</IonLabel>
                                                <IonButtons slot="end" className="list-btns" onClick={() => deleteUnavailDate(date.id)}>
                                                    <IonButton className="list-btn">
                                                        <IonLabel className="delete-label">Delete</IonLabel>
                                                    </IonButton>
                                                </IonButtons>
                                            </IonItem>
                                            <hr />
                                        </>
                                    )}
                                </IonCard>
                            }
                            {selectedDatesList.length == 0 &&
                                <IonCard className="nodate-card list-card">
                                    <IonLabel className="nodate-label">No Data Available</IonLabel>
                                </IonCard>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
                    <IonContent className="model-bg">
                        <IonGrid className='cnfmModelGrid'>
                            <IonRow>
                                <IonCol size="12" col-12>
                                    <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                </IonCol>
                                <IonCol size="12" col-12>
                                    <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                </IonCol>
                                <IonCol size="12" col-12>
                                    <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                </IonCol>
                                <IonCol size='12' col-12>
                                    <IonButton
                                        routerLink="/login"
                                        routerDirection="none"
                                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false) }} expand="block">
                                        Back to Login
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>

                <IonLoading
                    isOpen={loading}
                    spinner="circular"
                    translucent={true}
                />
                <IonToast
                    isOpen={showErrorToast}
                    onDidDismiss={() => setShowErrorToast(false)}
                    message={errorMessage}
                    duration={3000}
                    color="danger" />
                <IonToast
                    isOpen={showToasSuccess}
                    onDidDismiss={() => setShowToastSuccess(false)}
                    message={successMessage}
                    duration={3000}
                    color="success" />
            </IonContent>
            <WatiChat />
        </IonPage>
    );
};

export default Unavailabledates;
