import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './style.css';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { UIProvider } from "./my-context";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.ts').then(function(registration) {
      registration.onupdatefound = function() {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = function() {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // Force update by clearing the cache and reloading
                caches.keys().then(function(cacheNames) {
                  return Promise.all(
                    cacheNames.map(function(cacheName) {
                      return caches.delete(cacheName);
                    })
                  );
                }).then(function() {
                  window.location.reload();
                });
              }
            }
          };
        }
      };
    }).catch(function(error) {
      console.log('ServiceWorker registration failed: ', error);
    });
  });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
