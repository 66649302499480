// src/pages/NotFoundPage.tsx

import { IonButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonImg, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useHistory } from "react-router";

const NotFoundPage: React.FC = () => {
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <IonHeader className="ion-no-border">
                <IonToolbar className="light-mode-header-toolbar signup-toolbar">
                    <IonButtons slot="start">
                        <IonButton
                            className="end-btn"
                            onClick={goBack}
                        >
                            <IonIcon
                                className="fav header-icons"
                                src="assets/imgs/icons/backArrow.svg"
                                slot="end" />
                        </IonButton>
                    </IonButtons>
                    <IonImg className='img-404' src='https://glamdeva.com/source/assets/images/logo-alt.png'></IonImg>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="holder ion-text-center">
                    <IonCard className='card-404'>
                        <h4 className='h4-404'>404 - page not found</h4>
                        <p className='p-404'>We're sorry but the page you're looking for doesn't exist. Here are some suggestions to help you find the right pages.</p>
                        <IonButton
                            routerLink="/login"
                            routerDirection="none" className='fontName bc-to-login booking-details-btn' expand="block">
                            Back to Login
                        </IonButton>
                    </IonCard>
                </div>
            </IonContent>
        </>
    );
};

export default NotFoundPage;
