import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import apiConfig from "../../apiConfig";
import "./Contactsupport.css";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";

import { App } from "@capacitor/app";
import { Device } from "@awesome-cordova-plugins/device";
import { isPlatform } from "@ionic/react";
import { Network } from "@capacitor/network";
import WatiChat from "../../components/WatiChat";

const ContactSupport: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);
  const [showToast, setShowToast] = useState(false);
  const [displayPicture, setDisplayPicture] = useState("");

  const [showLogoutCnfmModal, setShowLogoutCnfmModal] = useState(false);
  const [showDeletedModal, setshowDeletedModal] = useState(false);
  const [showDeleteCnfmModal, setShowDeleteCnfmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState("");
  const [supendedTitle, setSupendedTitle] = useState("");
  const [showActionSheet, setShowActionSheet] = useState(false);
  const capturedImagesRef = useRef<File[]>([]);
  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [chatList, setChatList] = useState<Array<any[]>>([]);
  const [partnerMessage, setPartnerMessage] = useState("");

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);

  const goBack = () => {
    history.goBack();
  };

  useIonViewWillEnter(() => {
    console.log("Platform", Device.platform);
    getChatList();
    setDisplayPicture(`${localStorage.getItem("userImage")}`);
    console.log("image", localStorage.getItem("userImage"));

    console.log("darkModeValue", localStorage.getItem("darkMode"));
    if (localStorage.getItem("darkMode") == "darkMode") {
      setIsDarkModeActive(true);
    } else {
      setIsDarkModeActive(false);
    }
  }, []);

  const handleToggleChange = (event: any) => {
    console.log("event.detail.checked", event.detail.checked);

    const isChecked = event.detail.checked;
    console.log("isChecked", isChecked);

    setIsDarkModeActive(isChecked);
    if (isChecked == true) {
      localStorage.setItem("darkMode", "darkMode");
    } else {
      localStorage.setItem("darkMode", "lightMode");
    }
  };

  const handleChatRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
        getChatList();
      event.detail.complete();
    }, 1000);
  };

  const getChatList = () => {
    setLoading(true);
    setBackdropVisible(true);
    axios
      .get(
        `${
          apiConfig.baseUrl
        }/partnercommunication?partnerid=${localStorage.getItem("userId")}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          setChatList(response.data.data);
          setLoading(false);
          setBackdropVisible(false);
          setSuccessMessage(response.data.message);
        } else {
          setLoading(false);
          setBackdropVisible(false);
          setErrorMessage(response.data.message);
          setShowToast(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          setSupendedMsg(
            "Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification."
          );
          setSupendedTitle("Inactive");
          setShowActionSheet(false);
          setShowSuspendedModal(true);
        }
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
  };

  const handlePartnerMessage = (partnerMessage: any) => {
    setPartnerMessage(partnerMessage);
  };

  const submitPartnerMessage = () => {

    if(partnerMessage !== ""){
    setLoading(true);
    setBackdropVisible(true);
    // const formData = new FormData();

    // formData.append('provider_image', JSON.stringify(provider_image));

    axios
      .post(
        `${apiConfig.baseUrl}/partnercommunication`,
        {
          partnerid: localStorage.getItem("userId"),
          notes: partnerMessage,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              "tokenType"
            )} ${localStorage.getItem("accessToken")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          getChatList();
          setPartnerMessage("");
          setLoading(false);
          setBackdropVisible(false);
          setShowToastSuccess(true);
          setSuccessMessage(response.data.message);
        } else {
          setLoading(false);
          setBackdropVisible(false);
          setErrorMessage(response.data.message);
          setShowToast(true);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          setSupendedMsg(
            "Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification."
          );
          setSupendedTitle("Inactive");
          setShowActionSheet(false);
          setShowSuspendedModal(true);
        }
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
    } else {
        setErrorMessage("Please type anything...");
        setShowToast(true);
    }
  };

  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == true && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">
                Profile
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="no-network-div">
              <IonImg
                className="no-network-img"
                src="assets/imgs/images/darkNoNetwork.svg"
              ></IonImg>
              <h6 style={{ color: "#CCC" }} className="fontName">
                Check your internet connection and try again
              </h6>
            </div>
          </IonContent>
        </>
      )}
      {networkStatus == true && isDarkModeActive == true && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">
                Profile
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToast(false)}
              message={successMessage}
              duration={3000}
              color="success"
            />
          </IonContent>
        </>
      )}
      {networkStatus == false && isDarkModeActive == false && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">
                Profile
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="no-network-div">
              <IonImg
                className="no-network-img"
                src="assets/imgs/images/noNetwork.svg"
              ></IonImg>
              <h6 className="fontName">
                Check your internet connection and try again
              </h6>
            </div>
          </IonContent>
        </>
      )}
      {networkStatus == true && isDarkModeActive == false && (
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton className="end-btn" onClick={goBack}>
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ textAlign: "center" }}>Lets Chat</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
          <IonRefresher slot="fixed" onIonRefresh={handleChatRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            <IonGrid className="center-grid chat-grid">
              <IonRow>
                {chatList &&
                  chatList.map((chats: any, i) =>
                    chats.message_from == "admin" ? (
                      <IonCol size="12" key={i}>
                        <IonCard key={i} className="admin-card">
                          <IonCardContent className="partner-card-content">{chats.notes}</IonCardContent>
                        </IonCard>
                        <div>
                        <IonLabel className="partner-card-content font-name-bold admin-date-label">Admin</IonLabel>
                        <IonLabel className="partner-card-content admin-date-label">{chats.created_at}</IonLabel>
                        </div>
                      </IonCol>
                    ) : chats.message_from == "partner" ? (
                      <IonCol size="12">
                        <IonCard className="partner-card">
                          <IonCardContent className="partner-card-content">
                            {chats.notes}
                          </IonCardContent>
                        </IonCard>
                        <div>
                        {/* <IonLabel className="partner-card-content font-name-bold partner-date-label">You</IonLabel> */}
                        <IonLabel className="partner-card-content partner-date-label">{chats.created_at}</IonLabel>
                        </div>
                       
                      </IonCol>
                    ) : null
                  )}
              </IonRow>
            </IonGrid>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToast(false)}
              message={successMessage}
              duration={3000}
              color="success"
            />
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        type="text"
                        style={{
                          background: "#FFFFFF",
                          border: "2px solid #12877733",
                          width: "80%",
                          margin: "unset",
                        }}
                        className="form-control-model-fp-login fontName"
                        placeholder="Write a comment"
                        value={partnerMessage}
                        onChange={(e) => handlePartnerMessage(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        onFocus={undefined}
                      />

                      <IonButtons>
                        <IonButton
                          className="end-btn"
                          onClick={submitPartnerMessage}
                        >
                          <IonIcon
                            className="fav chat-icon"
                            src="assets/imgs/icons/sendChat.svg"
                          />
                        </IonButton>
                      </IonButtons>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </IonFooter>
        </>
      )}
      <WatiChat />
    </IonPage>
  );
};

export default ContactSupport;
