import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
  SearchbarChangeEventDetail,
} from "@ionic/react";
import { useEffect, useState } from "react";
import apiConfig from "../../apiConfig";
import "./Wallet.css";
import axios from 'axios';
import { Link, useHistory, useLocation } from "react-router-dom";

import { Network } from '@capacitor/network';
import { Filesystem, Directory } from '@capacitor/filesystem';
import WatiChat from "../../components/WatiChat";

const Wallet: React.FC = () => {

  const darkModeValue = localStorage.getItem("darkMode");
  const history = useHistory();
  const [activeSegment, setActiveSegment] = useState<string>("activeServices");
  const [products, setProducts] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [isProductListLoading, setIsProductListLoading] = useState(true);
  const [InactiveProducts, setInactiveProducts] = useState<Array<any>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInactiveQuery, setSearchInactiveQuery] = useState('');


  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [editDetailsCat, setEditDetailsCat] = useState<any>();
  const [editDetailsSubCat, setEditDetailsSubCat] = useState<any>();
  const [editDetailsStyle, setEditDetailsStyle] = useState<any>();

  const [editFormData, setEditFormData] = useState({
    "service_id": '',
    "price": "0.00",
    "costmiles": "0.00",
    "minmiles": "0.00",
    // "status": 0,
    "catid": "",
    "subcatid": "",
    "style_id": "",
    "subcat_title": "",
    "userid": "",
    "images": [{ url: '' }]
  });

  const [selectedFile, setSelectedFile] = useState<File>();
  const [capturedImages, setCapturedImages] = useState<File[]>([]);
  const [toggleStatus, setToggleStatus] = useState(Boolean);

  const [errorMessage, setErrorMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handlePictureUpload = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = true;

    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const files = Array.from(target.files);

        setLoading(true); // Assuming this sets some loading state in your UI
        setBackdropVisible(true); // Assuming this shows some backdrop in your UI

        const newImageUrls: { url: string }[] = []; // This will store the data URLs of the new images

        try {
          for (const file of files) {
            const dataUrl = await readFileAsDataUrl(file); // This now returns a string explicitly
            newImageUrls.push({ url: dataUrl }); // This should match the type of your images array
          }

          // Combine old and new images
          const updatedImages = [...editFormData.images, ...newImageUrls];

          // Update the editFormData state with the new images list
          setEditFormData({ ...editFormData, images: updatedImages });
        } catch (error) {
          console.error('Error reading images:', error);
        } finally {
          setLoading(false);
          setBackdropVisible(false);
        }
      }
    };

    input.click();
  };

  function readFileAsDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('FileReader did not return a string.'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const removeImage = (index: any) => {
    // Use a functional update to ensure you have the latest state
    setEditFormData(prevFormData => {
      // Filter out the image at the given index
      const newImages = prevFormData.images.filter((_, i) => i !== index);
      // Return the new state with the updated images array
      return { ...prevFormData, images: newImages };
    });
  };



  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
    servicesCountFunc();
    if (activeSegment == "activeServices") {
      activeService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    } else {
      inactiveService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    }
    // checkAndroidVersion();
    // generateItems();
  }, [])

  const editModelFunction = (subcat: any, style: any, user: any) => {
    setShowEditModal(true);

    setEditDetailsCat(user);
    setEditDetailsSubCat(subcat);
    setEditDetailsStyle(style);

    setEditFormData({
      ...editFormData,
      ['service_id']: style.id,
      ['price']: style.price,
      ['costmiles']: style.costmiles,
      ['minmiles']: style.minmiles,
      // ['status']: style.status,
      ['catid']: style.catid,
      ['subcatid']: style.subcatid,
      ['style_id']: style.style_id,
      ['subcat_title']: style.subcat_title,
      ['userid']: `${localStorage.getItem("userId")}`,
      ['images']: style.images
    })

  }

  const handleEditValues = (field: string, value: any) => {
    setEditFormData({
      ...editFormData,
      [field]: value,
    });
  }

  const updateService = (style: any, field: any) => {
    if (editFormData.price !== '' && editFormData.costmiles !== '' && editFormData.minmiles !== '') {
      setLoading(true);
      setBackdropVisible(true);
      if (style.id == null) {
        axios.post(`${apiConfig.baseUrl}/services-details-update`, editFormData, {
          headers: {
            'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            setActiveCount(res.data.data.length);
            if (res.data.status == true) {
              setLoading(false);
              setBackdropVisible(false);
              if (field == 'active') {
                activeService();
              } else {
                inactiveService();
              }
              setShowEditModal(false)
              setIsProductListLoading(true);
            } else {
              setBackdropVisible(false);
              setLoading(false);
              setIsProductListLoading(false);
              // setDisplayIndex(10);
            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch((error) => {
            console.log('Errorrrrr-->>', error);
            if (error.response.status == 401) {
              setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
              setSupendedTitle("Inactive");
              setShowSuspendedModal(true);
            }
            setLoading(false);
            setIsProductListLoading(false);
          })
      } else {
        axios.post(`${apiConfig.baseUrl}/services-details-update`, {
          service_id: style.id,
          price: editFormData.price,
          costmiles: editFormData.costmiles,
          minmiles: editFormData.minmiles,
        }, {
          headers: {
            'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            setShowToastSuccess(true);
            setSuccessMessage(res.data.message);
            if (res.data.status == true) {
              setLoading(false);
              setBackdropVisible(false);
              if (field == 'active') {
                activeService();
              } else {
                inactiveService();
              }
              setShowEditModal(false)
              setIsProductListLoading(true);
            } else {
              setBackdropVisible(false);
              setLoading(false);
              setIsProductListLoading(false);
              // setDisplayIndex(10);

            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch((error) => {
            console.log('Errorrrrr-->>', error);
            if (error.response.status == 401) {
              setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
              setSupendedTitle("Inactive");
              setShowSuspendedModal(true);
            }
            setLoading(false);
            setIsProductListLoading(false);
          })
      }
    } else {
      setErrorMessage("Please enter all the inputs");
      setShowToast(true);
    }



  }

  const handleToggleChange = (event: any, style: any, field: any) => {
    // const newStatus = event.detail.checked ? 1 : 2;
    // setToggleStatus(newStatus);
    const isChecked = event.detail.checked == true ? 1 : 2;;
    console.log("isChecked", isChecked);

    setLoading(true);
    setBackdropVisible(true);

    if (style.id == null) {
      axios.post(`${apiConfig.baseUrl}/services-details-update`, {
        status: isChecked,
        price: style.price,
        costmiles: style.costmiles,
        minmiles: style.minmiles,
        catid: style.catid,
        style_id: style.style_id,
        subcatid: style.subcatid,
        subcat_title: style.subcat_title,
        userid: localStorage.getItem("userId"),


      }, {
        headers: {
          'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          setShowEditModal(false);
          if (field == 'active') {
            activeService();
          } else {
            inactiveService();
          }
          // setLoading(false);
          // setBackdropVisible(false);

          // setTimeout(() => {
          //   if (loading == true) {
          //     setLoading(false);
          //     setBackdropVisible(false);
          //     // setErrorMessage("Something went wrong on API please try again");
          //     // setShowToast(true);
          //   }
          // }, 10000);
        })
        .catch((error) => {
          console.log('Errorrrrr-->>', error);
          if (error.response.status == 401) {
            setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
            setSupendedTitle("Inactive");
            setShowSuspendedModal(true);
          }
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          // setLoading(false);
          // setBackdropVisible(false);
          setIsProductListLoading(false);
        })
    } else {
      axios.post(`${apiConfig.baseUrl}/services-details-update`, {
        "status": isChecked,
        "service_id": style.id
      }, {
        headers: {
          'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          setShowToastSuccess(true);
          setSuccessMessage(res.data.message);
          setShowEditModal(false);
          if (field == 'active') {
            activeService();
          } else {
            inactiveService();
          }
          // setLoading(false);
          // setBackdropVisible(false);

          // setTimeout(() => {
          //   if (loading == true) {
          //     setLoading(false);
          //     setBackdropVisible(false);
          //     // setErrorMessage("Something went wrong on API please try again");
          //     // setShowToast(true);
          //   }
          // }, 10000);
        })
        .catch((error) => {
          console.log('Errorrrrr-->>', error);
          if (error.response.status == 401) {
            setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
            setSupendedTitle("Inactive");
            setShowSuspendedModal(true);
          }
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(false);
        })
    }

  }

  const servicesCountFunc = () => {
    axios.get(`${apiConfig.baseUrl}/service_count?userid=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setActiveCount(res.data.data.active_count);
        setInactiveCount(res.data.data.inactive_count);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
      })
  }
  const activeService = () => {
    setProducts([]);
    setLoading(true);
    setBackdropVisible(true);
    axios.get(`${apiConfig.baseUrl}/services?active=true&userid=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        servicesCountFunc();

        if (res.data.data.length === 0) {
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(true);
        } else {
          setProducts(res.data.data);
          setBackdropVisible(false);
          setLoading(false);
          setIsProductListLoading(false);
          // setDisplayIndex(10);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
          setShowSuspendedModal(true);
        }
        setLoading(false);
        setIsProductListLoading(false);
      })
  }

  const inactiveService = () => {
    setLoading(true);
    setBackdropVisible(true);
    setInactiveProducts([]);
    setProducts([]);
    axios.get(`${apiConfig.baseUrl}/services?active=false&userid=${localStorage.getItem('userId')}`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {

        servicesCountFunc();

        if (res.data.data.length === 0) {
          setLoading(false);
          setBackdropVisible(false);
          setIsProductListLoading(true);
        } else {
          setInactiveProducts(res.data.data);
          setBackdropVisible(false);
          setLoading(false);
          setIsProductListLoading(false);
          // setInactiveDisplayIndex(10);
        }

        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
          setShowSuspendedModal(true);
        }
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
        setIsProductListLoading(false);
      })
  }

  const activeServiceSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    console.log("e.detail.value", e.detail.value);
    setSearchQuery(value ?? '');
  };
  const inActiveServiceSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setSearchInactiveQuery(value ?? '');
  };


  const segmentChanged = (e: any) => {
    console.log(activeSegment);
    setActiveSegment(e.detail.value);
    if (e.detail.value == "activeServices") {
      activeService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    } else {
      inactiveService();
      setSearchQuery('');
      setSearchInactiveQuery('');
    }
  };


  const goBack = () => {
    history.goBack();
  };


  const viewService = (e: any) => {
    localStorage.setItem('productId', e);
  }

  const handleActiveRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      activeService();
      event.detail.complete();
    }, 1000);
  };

  const handleInactiveRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      inactiveService();
      event.detail.complete();
    }, 1000);
  };

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">
                Services
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">
                Services
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            {activeSegment === "activeServices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleActiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {activeSegment === "inactiveservices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleInactiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {activeSegment === "activeServices" && (
                <IonSearchbar
                  placeholder="Search Active Services"
                  style={{ width: "95%" }}
                  className="dark-search-bar"
                  mode="ios"
                  debounce={1000}
                  onIonChange={activeServiceSearch}
                ></IonSearchbar>
              )}
              {activeSegment === "inactiveservices" && (
                <IonSearchbar
                  style={{ width: "95%" }}
                  className="dark-search-bar"
                  placeholder='Search Inactive Service'
                  mode="ios"
                  debounce={1000}
                  onIonChange={inActiveServiceSearch}
                ></IonSearchbar>
              )}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment
                  onIonChange={(e) => segmentChanged(e)}
                  value={activeSegment as any}
                >
                  <IonSegmentButton value={"activeServices"} className="dark-custom-segment-button">
                    <h6 style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Active Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{activeCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton value={"inactiveservices"} className="dark-custom-segment-button">
                    <h6 style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Inactive Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{inactiveCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              {activeSegment === "activeServices" ? (
                <div className="send-money-holder animate__animated animate__fadeInRight animate__slow">
                  <div className="the-title p-add-title ion-padding-horizontal">
                    <IonLabel className="dark-dash-main-title">Add new products / Services</IonLabel>
                  </div>

                  <div className="contact-holder">
                    <Link className="link-decoration" to={{ pathname: '/addproduct' }}>
                      {/* <IonButton routerLink="/addproduct" routerDirection="none"> */}
                      <div className="recip-card ion-text-center">
                        <div className="add-btn-holder">
                          <img src="assets/imgs/icons/plus.svg" alt="" />
                        </div>
                        <p className="ion-text-wrap">
                          Add your <br /> products
                        </p>
                      </div>
                      {/* </IonButton> */}
                    </Link>
                    {products.map((user, i) => (
                      <div key={i} className="recip-card ion-text-center">
                        <IonAvatar>
                          <img src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="" />
                        </IonAvatar>
                        <h6 className='ion-text-wrap slide-img-title fontName scroll-image-title text-ellipsis'>
                          {user.title}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {activeSegment === "activeServices" ? (
                <div className="dark-empty-div">
                  <span> </span>
                </div>
              ) : (
                <div></div>
              )}
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "activeServices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 style={{
                                color: "#CCCCCC", textAlign: "center",
                                marginTop: "12%",
                                fontSize: "16px"
                              }} className="fontName nocardh6">Sorry, there are no Active List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredActiveList = products
                              // .slice(0, displayIndex)
                              .filter(result => (result.title && result.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                                (result.description && result.description.toLowerCase().includes(searchQuery.toLowerCase()))
                              );
                            if (filteredActiveList.length === 0 && searchQuery !== "") {
                              return (
                                <IonCol>
                                  <div className="dark-fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 style={{
                                      color: "#CCCCCC", textAlign: "center",
                                      marginTop: "12%",
                                      fontSize: "16px"
                                    }} className="dark-fontName nocardh6">Sorry, there are no Active Lists for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredActiveList.map((user, i) => (
                                <IonCol key={i} size='6' size-md='3' className="col-p0">
                                  <Link className="link-decoration" onClick={() => viewService(user.id)} to={'/addincome'}>
                                    <IonGrid className='dark-fontName p-0 productGrid'>
                                      <IonRow>
                                        <IonCol className="col-p0" size='12'>
                                          <div className="dark-fontName image-containers">
                                            <img className="product-img" src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="Product Image" />
                                          </div>
                                          <div className="dark-image-title-card">
                                            <IonLabel className='dark-fontName dark-image-title text-ellipis'>{user.title}</IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>

                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "inactiveservices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol>
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 style={{
                                color: "#CCCCCC", textAlign: "center",
                                marginTop: "12%",
                                fontSize: "16px"
                              }} className="fontName nocardh6">Sorry, there are no Inactive List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredInactiveList = InactiveProducts
                              // .slice(0, inactiveDisplayIndex)
                              .filter(result => result.title.toLowerCase().includes(searchInactiveQuery.toLowerCase()) ||
                                result.description.toLowerCase().includes(searchInactiveQuery.toLowerCase())
                              );
                            if (filteredInactiveList.length === 0 && searchInactiveQuery !== "") {
                              return (
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 style={{
                                      color: "#CCCCCC", textAlign: "center",
                                      marginTop: "12%",
                                      fontSize: "16px"
                                    }} className="fontName nocardh6">Sorry, there are no Inactive List for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredInactiveList.map((user, i) => (
                                <IonCol key={i} size-md='3' size='6' className="col-p0">
                                  <Link className="link-decoration" onClick={() => viewService(user.id)} to={'/addincome'}>
                                    <IonGrid className='fontName p-0 productGrid'>
                                      <IonRow>
                                        <IonCol col-12 size='12' className="col-p0">
                                          <div className="fontName image-containers">
                                            <img className="product-img" src={user.image ? user.image : "assets/imgs/images/no-image.jpg"} alt="Product Image" />
                                          </div>
                                          <div className="dark-image-title-card">
                                            <IonLabel className='dark-fontName dark-image-title text-ellipis'>{user.title}</IonLabel>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </Link>
                                </IonCol>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-inactive" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => setShowSuspendedModal(false)}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="light-ion-title">
              Services
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="wallet">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="light-ion-title">
              Services
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/bell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="wallet">
            {activeSegment === "activeServices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleActiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {activeSegment === "inactiveservices" && (
              <IonRefresher slot="fixed" onIonRefresh={handleInactiveRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {/* {activeSegment === "activeServices" && (
                <IonSearchbar
                  placeholder="Search Active Services"
                  style={{ width: "95%" }}
                  className="search-bar"
                  mode="ios"
                  debounce={1000}
                  onIonChange={activeServiceSearch}
                ></IonSearchbar>
              )}
              {activeSegment === "inactiveservices" && (
                <IonSearchbar
                  style={{ width: "95%" }}
                  className="search-bar"
                  placeholder='Search Inactive Service'
                  mode="ios"
                  debounce={1000}
                  onIonChange={inActiveServiceSearch}
                ></IonSearchbar>
              )} */}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment
                  onIonChange={(e) => segmentChanged(e)}
                  value={activeSegment as any}
                >
                  <IonSegmentButton value={"activeServices"} className="custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Active Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{activeCount}</span></h6>

                  </IonSegmentButton>
                  <IonSegmentButton value={"inactiveservices"} className="custom-segment-button">
                    <h6 style={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "baseline"
                    }} className="light-mode-segements">Inactive Services <span style={{ margin: "-4px 0px 0px 8px" }} className='spanCount'>{inactiveCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "activeServices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol size="12">
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%"
                              }} className="fontName nocardh6">Sorry, there are no Active List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredActiveList = products
                              .filter(result => (result.title && result.title.toLowerCase().includes(searchQuery.toLowerCase()))
                              );
                            if (filteredActiveList.length === 0 && searchQuery !== "") {
                              return (
                                <IonCol size="12">
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 style={{
                                      color: "#515151",
                                      textAlign: "center",
                                      marginTop: "11%"
                                    }} className="fontName nocardh6">Sorry, there are no Active Lists for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredActiveList.map((user, i) => (
                                <>
                                  <IonCol key={i} size="12">
                                    <IonLabel className='fontName image-title'>{user.title}</IonLabel>
                                  </IonCol>
                                  {user.subcategory && user.subcategory.map((subcategory: any, i: any) => (
                                    subcategory.styles.map((style: any, j: any) => (
                                      <IonCol key={j} size-md="6" size-lg="4" size="12">
                                        <IonCard className="overall-list">
                                          <IonGrid>
                                            <IonRow>
                                              <IonCol size-md='1' size='2'>
                                                <IonAvatar className="product-img">
                                                  {style.images.length !== 0 &&
                                                    <IonImg src={style.images[0].url ? style.images[0].url : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                                  }
                                                  {style.images.length == 0 &&
                                                    <IonImg src="assets/imgs/images/no-image.jpg" alt=""></IonImg>
                                                  }
                                                </IonAvatar>
                                              </IonCol>
                                              <IonCol size-md='8' size="6" className="second-col">
                                                <div className="d-flex style-title-div">
                                                  <IonLabel className="style-first-title service-title-pwa">{style.styletitle}</IonLabel>
                                                  <br />
                                                  <IonLabel className="sub-cat-title">{subcategory.title}</IonLabel>
                                                  <br />
                                                </div>
                                              </IonCol>
                                              <IonCol size-md='3' size="4" className="second-col">
                                                <div className="div-align ion-float-right">
                                                  <IonButtons >
                                                    <IonButton
                                                      onClick={() => editModelFunction(subcategory, style, user)} expand="block"
                                                    >
                                                      <IonIcon
                                                        className="fav edit-icon"
                                                        src="assets/imgs/icons/editIcon.svg"
                                                      />
                                                    </IonButton>
                                                  </IonButtons>
                                                </div>
                                              </IonCol>
                                              <IonCol size-md='4' size='4'>
                                                <div className="first-div border-right">
                                                  <IonLabel className="my-price-title">£{Number(style.price).toFixed(2)}</IonLabel>
                                                  <br />
                                                  <IonLabel className="my-price-sub-title">My Local Price</IonLabel>
                                                </div>
                                              </IonCol><IonCol size-md='4' size='4'>
                                                <div className="first-div border-right">
                                                  <IonLabel className="fee-title">£{Number(style.glamdeva_fee).toFixed(2)}</IonLabel>
                                                  <br />
                                                  <IonLabel className="fee-sub-title">Glamdeva Fee</IonLabel>
                                                </div>
                                              </IonCol><IonCol size-md='4' size='4'>
                                                <div className="first-div">
                                                  <IonLabel className="net-price-title">£{Number(style.net_price).toFixed(2)}</IonLabel>
                                                  <br />
                                                  <IonLabel className="net-price-sub-title">Net Price</IonLabel>
                                                </div>
                                              </IonCol>
                                              <IonCol size="12">
                                                <div className="action-div">
                                                  <IonLabel className="booking-id-chip free-miles-chip">{style.costmiles} Free Miles</IonLabel>
                                                  <IonLabel className="mile-coverage-chip">{style.minmiles} Miles Coverage</IonLabel>
                                                  <IonToggle
                                                    className="toggleActive  ion-float-right"
                                                    slot="end"
                                                    checked={style.status == "1" ? true : false}
                                                    onIonChange={(e) => handleToggleChange(e, style, 'active')}
                                                  ></IonToggle>
                                                </div>
                                              </IonCol>
                                            </IonRow>
                                          </IonGrid>
                                        </IonCard>

                                      </IonCol>
                                    ))
                                  ))
                                  }
                                  <div className="empty-div">
                                    <span> </span>
                                  </div>
                                </>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>

                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                {activeSegment === "inactiveservices" ? (
                  <div className="the-list">
                    <IonGrid>
                      <IonRow>
                        {isProductListLoading ? (
                          <IonCol size="12">
                            <div className="fontName nocard">
                              <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                              <h6 style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%"
                              }} className="fontName nocardh6">Sorry, there are no Inactive List for you</h6>
                            </div>
                          </IonCol>
                        ) : (
                          (() => {
                            const filteredInactiveList = InactiveProducts
                              // .slice(0, inactiveDisplayIndex)
                              .filter(result => result.title.toLowerCase().includes(searchInactiveQuery.toLowerCase())
                              );
                            if (filteredInactiveList.length === 0 && searchInactiveQuery !== "") {
                              return (
                                <IonCol size="12">
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 style={{
                                      color: "#515151",
                                      textAlign: "center",
                                      marginTop: "11%"
                                    }} className="fontName nocardh6">Sorry, there are no Inactive List for you</h6>
                                  </div>
                                </IonCol>
                              );
                            } else {
                              return filteredInactiveList.map((user, i) => (
                                <>
                                  <IonCol key={i} size="12">
                                    <IonLabel className='fontName image-title text-ellipis'>{user.title}</IonLabel>
                                  </IonCol>
                                  {user.subcategory && user.subcategory.map((subcategory: any, i: any) => (
                                    subcategory.styles.map((style: any, j: any) => (
                                      <IonCol key={j} size-md="6" size-lg="4" size="12">
                                        <IonCard className="overall-list" style={{ borderBottom: "3px solid #ED1C24" }}>
                                          <IonGrid>
                                            <IonRow>
                                              <IonCol size-md='1' size='2'>
                                                <IonAvatar className="product-img">
                                                  {style.images.length !== 0 &&
                                                    <IonImg src={style.images[0].url ? style.images[0].url : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                                  }
                                                  {style.images.length == 0 &&
                                                    <IonImg src="assets/imgs/images/no-image.jpg" alt=""></IonImg>
                                                  }
                                                </IonAvatar>
                                              </IonCol>
                                              <IonCol size-md='8' size="6" className="second-col">
                                                <div className="d-flex style-title-div">
                                                  <IonLabel className="style-first-title service-title-pwa">{style.styletitle}</IonLabel>
                                                  <br />
                                                  <IonLabel className="sub-cat-title">{subcategory.title}</IonLabel>
                                                  <br />
                                                </div>
                                              </IonCol>
                                              <IonCol size-md='3' size="4" className="second-col">
                                                <div className="div-align ion-float-right">
                                                  <IonButtons >
                                                    <IonButton
                                                      onClick={() => editModelFunction(subcategory, style, user)} expand="block"
                                                    >
                                                      <IonIcon
                                                        className="fav edit-icon"
                                                        src="assets/imgs/icons/editIcon.svg"
                                                      />
                                                    </IonButton>
                                                  </IonButtons>
                                                </div>
                                              </IonCol>
                                              <IonCol size-md='4' size='4'>
                                                <div className="first-div border-right">
                                                  <IonLabel className="my-price-title">£{Number(style.price).toFixed(2)}</IonLabel>
                                                  <br />
                                                  <IonLabel className="my-price-sub-title">My Local Price</IonLabel>
                                                </div>
                                              </IonCol><IonCol size-md='4' size='4'>
                                                <div className="first-div border-right">
                                                  <IonLabel className="fee-title">£{Number(style.glamdeva_fee).toFixed(2)}</IonLabel>
                                                  <br />
                                                  <IonLabel className="fee-sub-title">Glamdeva Fee</IonLabel>
                                                </div>
                                              </IonCol><IonCol size-md='4' size='4'>
                                                <div className="first-div">
                                                  <IonLabel className="net-price-title">£{Number(style.net_price).toFixed(2)}</IonLabel>
                                                  <br />
                                                  <IonLabel className="net-price-sub-title">Net Price</IonLabel>
                                                </div>
                                              </IonCol>
                                              <IonCol size="10">
                                                <IonLabel className="booking-id-chip free-miles-chip">{style.costmiles} Free Miles</IonLabel>
                                                <IonLabel className="mile-coverage-chip">{style.minmiles} Miles Coverage</IonLabel>
                                              </IonCol>
                                              <IonCol className="postcode-col" size="2">
                                                <div className="div-align ion-float-right">
                                                  <IonToggle
                                                    className="toggleActive"
                                                    slot="end"
                                                    checked={style.status == "1" ? true : false}
                                                    onIonChange={(e) => handleToggleChange(e, style, 'inactive')}
                                                  ></IonToggle>
                                                </div>
                                              </IonCol>
                                            </IonRow>
                                          </IonGrid>
                                        </IonCard>
                                      </IonCol>
                                    ))
                                  ))
                                  }
                                </>
                              ));
                            }
                          })()
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <IonModal
              id="example-modal-edit"
              isOpen={showEditModal}
              onDidDismiss={() => setShowEditModal(false)}
              backdropDismiss={false}
              initialBreakpoint={0.75}
              breakpoints={[0, 0.25, 0.5, 0.75, 1]}
              handleBehavior="cycle"
            >
              <IonContent className="ion-padding">
                <IonGrid>
                  <IonRow>
                    <IonCol size="6"><IonLabel className="service-title-status">Service Status</IonLabel></IonCol>
                    <IonCol size="6">
                      <div className="div-align ion-float-end">
                        {editFormData && editDetailsStyle && (
                          <>
                            {
                              editDetailsStyle.status === 1
                                ? <IonLabel className="edit-active-chip">Active</IonLabel>
                                : <IonLabel className="edit-inactive-chip">Inactive</IonLabel>
                            }
                            <IonToggle
                              checked={editDetailsStyle.status == 1 ? true : false}
                              onIonChange={(e) => { handleToggleChange(e, editDetailsStyle, editDetailsStyle.status == 1 ? 'active' : 'inactive'); }}
                            ></IonToggle>
                          </>
                        )}
                      </div>
                    </IonCol>
                    <IonCol size="12" style={{ marginTop: "5%" }}>
                      {editDetailsCat && editDetailsStyle && editDetailsSubCat && editFormData && (
                        <>
                          <IonLabel className="edit-style-title">{editDetailsStyle.styletitle}</IonLabel>
                          <br />
                          <IonLabel className="edit-cat-title">{editDetailsCat.title} &gt;</IonLabel> <IonLabel className="edit-subcat-title">{editDetailsSubCat.title}</IonLabel>
                          <br />
                          <IonCol size="12">
                            <div className="edit-model-container">
                              <input
                                className='form-control-model-fp fontName'
                                value={editFormData.price}
                                onChange={e => {
                                  let value = e.target.value;
                                  // Check if the input is a number
                                  if (value.trim() === '') {
                                    // If the input is cleared or invalid, handle accordingly
                                    // Assuming handleEditValues can handle empty strings or you want to reset the value
                                    handleEditValues('price', '');
                                  } else if (!isNaN(value as any) && !isNaN(parseFloat(value))) {
                                    // Parse the value to a number and pass it if it's a valid number
                                    handleEditValues('price', parseFloat(value));
                                  }
                                }}
                                onFocus={(e) => e.preventDefault()}
                              />
                              <label className='eye-icon input-pound-label'>pounds</label>
                              <label className="label-outline-model-edit" htmlFor="textAreaExample">My Local Price (£)</label>
                            </div>
                          </IonCol>
                          <IonCol size="12">
                            <div className="edit-model-container">
                              <input
                                className='form-control-model-fp fontName'
                                value={editFormData.costmiles}
                                onChange={e => {
                                  let value = e.target.value;
                                  // Check if the input is a number
                                  if (value.trim() === '') {
                                    // If the input is cleared or invalid, handle accordingly
                                    // Assuming handleEditValues can handle empty strings or you want to reset the value
                                    handleEditValues('costmiles', '');
                                  } else if (!isNaN(value as any) && !isNaN(parseFloat(value)) && value.length <= 4) {
                                    // Parse the value to a number and pass it if it's a valid number
                                    handleEditValues('costmiles', parseFloat(value));
                                  }
                                }}
                                onFocus={(e) => e.preventDefault()}
                              />
                              <label className='eye-icon input-pound-label' >miles</label>
                              <label className="label-outline-model-edit" htmlFor="textAreaExample">Free Miles</label>
                            </div>
                          </IonCol>
                          <IonCol size="12">
                            <div className="edit-model-container">
                              <input
                                className='form-control-model-fp fontName'
                                value={editFormData.minmiles}
                                onChange={e => {
                                  let value = e.target.value;
                                  // Check if the input is a number
                                  if (value.trim() === '') {
                                    // If the input is cleared or invalid, handle accordingly
                                    // Assuming handleEditValues can handle empty strings or you want to reset the value
                                    handleEditValues('minmiles', '');
                                  } else if (!isNaN(value as any) && !isNaN(parseFloat(value)) && value.length <= 4) {
                                    // Parse the value to a number and pass it if it's a valid number
                                    handleEditValues('minmiles', parseFloat(value));
                                  }
                                }}
                                onFocus={(e) => e.preventDefault()}
                              />
                              <label className='eye-icon input-pound-label' >miles</label>
                              <label className="label-outline-model-edit" htmlFor="textAreaExample">Max Coverage (miles)</label>
                            </div>
                          </IonCol>
                          {/* <IonCol size="12">
                            <div onClick={handlePictureUpload} className="edit-model-container">
                              <IonLabel className='form-control-model-upload fontName' ></IonLabel>
                              <button className='btn-upload'  >Upload</button>
                              <label className="label-outline-model-edit" htmlFor="textAreaExample">Choose Images</label>
                            </div>
                          </IonCol>
                          <IonGrid className="fontName image-container-add">
                            <IonRow>
                              {editFormData && Array.isArray(editFormData.images) && editFormData.images.map((img, index) =>
                                img.url && (
                                  <IonCol key={index} size="3">
                                    <IonImg className="fontName addProductImg" src={img.url} alt={`Image ${index}`} />
                                    <IonImg className="close-btn" onClick={() => removeImage(index)} src="assets/imgs/icons/closeIcon.svg"></IonImg>
                                  </IonCol>
                                )
                              )}
                            </IonRow>
                          </IonGrid> */}
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonButton expand="block" onClick={() => { updateService(editDetailsStyle, editDetailsStyle.status == 1 ? 'active' : 'inactive') }} className="primary-button send-fp">
                        <IonIcon
                          className="fav btn-icon"
                          src="assets/imgs/icons/editPlus.svg"
                          slot="end"
                        /><span className="order-span">Update Service</span>
                      </IonButton>
                      <br />
                      <IonLabel className="edit-cancel" onClick={() => setShowEditModal(false)}>Cancel</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      <WatiChat />
    </IonPage>
  );
};

export default Wallet;
